//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isPushSupported } from '@/notifications/Utils'
import * as Push from '@/notifications'
import { uiStore } from '@/store'
import ChatControls from './ChatControls'

export default {
  name: 'HeaderBar',
  components: {
    ChatControls,
    HeaderMenu: () => import('./HeaderMenu'),
    NotificationBar: () => import('./NotificationBar'),
    SearchBar: () => import('./SearchBar'),
    TaskDeskControls: () => import('@/components/MiddleColumn/TaskDesk/TaskDeskControls'),
    UserControls: () => import('./UserControls'),
  },
  data () {
    return {
      showNotificationBar: false,
    }
  },
  computed: {
    isTaskDeskOpen () {
      return uiStore.getters.isTaskDeskOpen
    },
    currentChat () {
      const { getters } = this.$store
      return getters.currentChat
    },
    hasChat () {
      return (
        !!this.$store.getters.entity(this.currentChat) ||
        !!this.$store.getters.chat(this.currentChat)
      )
    },
    chatIsTaskOrMeeting () {
      const entity = this.$store.getters.entity(this.currentChat)
      const chat = this.$store.getters.chat(this.currentChat)
      return entity?.chatType === 'task' || chat?.chatType === 'task' ||
        chat?.chatType === 'meeting' || entity?.chatType === 'meeting'
    },
  },
  created () {
    this.showNotificationBar = isPushSupported() &&
      window.localStorage.getItem('notification-bar-closed') !== 'true' &&
      window.Notification.permission !== 'granted'

    Push.onGranted(() => { this.showNotificationBar = false })
  },
}
