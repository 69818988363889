//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  callsStore,
  contactsStore,
  groupsStore,
  publicGroupsStore,
  teamsStore,
  uiStore,
} from '@/store'
import { isWebRTCSupported } from '@/utils'
import { mapGetters } from 'vuex'
import SelectedMessagesBar from './SelectedMessagesBar'
import { TdIconBtn, TdTooltip } from 'td-ui'
import { IconArrowLeftM } from '@tada/icons'
import i18n from '@/i18n'

export default {
  name: 'ChatControls',
  components: {
    ChatButtons: () => import('./ChatButtons'),
    ChatMembers: () => import('./ChatMembers'),
    ChatMuteIcon: () => import('./ChatMuteIcon'),
    GroupIcon: () => import('@/components/UI/GroupIcon'),
    OnlineStatus: () => import('@/components/Chat/OnlineStatus'),
    SelectedMessagesBar,
    TdIconBtn,
    IconArrowLeftM,
    TdTooltip,
  },
  props: {
    currentChat: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'chatIsGroup',
      'chatIsThread',
      'entity',
    ]),
    selectedMessagesIds () {
      return uiStore.getters.selectedMessagesIds
    },
    isGroup () {
      return this.chatIsGroup(this.currentChat)
    },
    isThread () {
      return this.chatIsThread(this.currentChat)
    },
    chatEntity () {
      return this.entity(this.currentChat) || {}
    },
    publicStatus () {
      return this.chatEntity.publicStatus?.status
    },
    isForBroadcast () {
      if (!this.isGroup) return false

      const group = groupsStore.state.data[this.currentChat]
      return group && group.readOnly
    },
    isForSneakpeek () {
      if (!this.isGroup) return false
      return publicGroupsStore.state.sneakpeekId === this.currentChat
    },
    isNotificationEnabled () {
      if (!this.isGroup) return false
      return groupsStore.getters.isNotificationsEnabled(this.currentChat)
    },

    showSystembot () {
      const contact = contactsStore.getters.contact(this.currentChat)
      return contact?.botname === 'systembot'
    },
    profileNotifications () {
      return this.$store.state?.profile.data
    },

    systembotNotifications () {
      return this.profileNotifications.systembotNotificationsEnabled ?? false
    },

    description () {
      return this.isGroup
        ? this.chatEntity.description
        : this.chatEntity.role
    },
    canCall () {
      if (
        !this.$options.isWebRTCSupported ||
        !callsStore.getters.isCallingEnabled()
      ) return false

      return this.chatEntity && this.chatEntity.canCall
    },
    lang () {
      return i18n.locale
    },
  },
  created () {
    this.$options.isWebRTCSupported = isWebRTCSupported()
  },
  methods: {
    openProfile () {
      let instance, payload
      if (teamsStore.getters.isSingleGroupTeam()) {
        instance = 'team-profile'
        payload = teamsStore.state.currentTeamId
      } else {
        instance = this.isGroup ? 'group-profile' : 'contact-profile'
        payload = this.currentChat
      }
      uiStore.actions.switchRightBarInstance({
        instance,
        payload,
      })
    },
    toggleMuteState (v) {
      if (this.isGroup) {
        groupsStore.actions.edit({
          groupId: this.currentChat,
          payload: { notifications_enabled: v },
        })
      }

      if (this.showSystembot) {
        contactsStore.actions.editContact({
          jid: this.profileNotifications.jid,
          params: { systembot_notifications_enabled: v },
        })
      }
    },
    showMembers () {
      uiStore.actions.showModal({
        instance: 'all-members',
        payload: { jid: this.chatEntity.jid },
      })
    },
    backToChat () {
      this.$router.push({
        name: 'Chat',
        params: {
          teamId: teamsStore.getters.currentTeam.uid,
          jid: `g-${this.chatEntity.parentChatId}`,
        },
      })
      window.goal('returnToChat', { returnToChat: 'Нажатие кнопки выхода из треда' })
    },
  },
}
